<template>
  <main id="main" class="w-screen h-screen flex flex-col overflow-y-scroll">
    <TopNav />
    <router-view />
    <FooterNav />
  </main>
</template>
<script>
import TopNav from "@/components/TopNav";
import FooterNav from "@/components/FooterNav";

export default {
  name: "App",
  components: { TopNav, FooterNav },
};
</script>
