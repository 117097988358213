<template>
  <div class="w-full bg-gray-100 flex ">
    <div
      class="container mx-auto bg-gray-100 flex flex-col items-center justify-center px-4 py-8 lg:py-16"
    >
      <div class="py-4 text-4xl text-center lg:text-5xl font-bold text-gray-900">{{ title }}</div>
      <div class="py-4 text-xl lg:text-3xl text-gray-900">{{ sub_title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleSubtitleSection",
  props: ["title", "sub_title"],
};
</script>
