<template>
  <div class="w-full flex justify-center my-8">
    <a :href="path">
      <div
        class="uppercase shadow-md transition-all duration-200 bg-green-700 text-2xl text-white p-2 rounded pointer hover:shadow-lg hover:bg-blue-700"
      >
        {{ text }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "LinkButton",
  props: ["path", "text", "color"],
};
</script>
