<template>
  <a
    :href="path"
    :class="[pathname === path ? 'border-gray-900' : '']"
    class="text-gray-900 transition py-1 border-transparent border-b-2 hover:border-gray-900 hover:border-b-2"
    >{{ text }}</a
  >
</template>

<script>
export default {
  name: "FooterLink",
  props: ["path", "text"],
  data() {
    return {
      pathname: window.location.pathname,
    };
  },
};
</script>

<style>
.transition {
  transition: border-color;
  transition-duration: 150ms;
}
</style>
