export default {
  header_title: "CORNFIELD ENGINEERING SOLUTIONS",
  header_subtitle: "Mechanical, Electrical, Control and Motion Solutions for Industry",
  intro_section: {
    title: "Bespoke Engineering For Industry",
    btn_text: "Contact Us",
    text: `Cornfield Engineering Solutions are a company dedicated to providing you with the solutions that will help make your machinery the best there is. To do that we offer you a range of services that are tailored to your needs and can be as big or small as best suits you.
    
    We offer a wide range of services from mechanical design to complete machinery design and build. We are Lenze Performance Partner for Systems Integration so we have access to some of the world's best drive solutions. We can provide simple inverter controlled motor solutions all the way through to multi axis motion solutions complete with gearboxes, couplings, brakes and all manner of other automation equipment.`,
  },
  control: {
    title: `Control
    Systems`,
    text: `Sometimes you just need to pass a problem onto somebody else, at Cornfield Engineering Solutions we are happy to accept it, and sometimes you just need an extra pair of hands, we can do that too.

    We are able to take your requirements and produce a mechanical design to suit, we can even manufacture it for you if that is what you need. We can also simply provide some additional design hours, either at our premises or your own, you decide.
    
    If you have expertise in a particular area but need it building into a complete system, then once again just give us a call and let us take the strain, we'll construct a system to integrate your expertise into something that delivers a system that meets your customers needs.`,
  },

  upgrades: {
    title: `Machinery 
    Upgrades`,
    text: `At Cornfield Engineering Solutions we are committed to providing you with the best of our experience and tallent to help you to produce industrial machinery for your future.

  Given that almost every industrial machine has electrical and electronic contol we are ready to help. PLC's, drives, HMI's and SCADA's are all within our capabilities along with electrical design, panel building and electrical installation.
  
  At Cornfield we can take responsibility for the entire control system on one of your machines, or just writing the software (PLC, HMI, SCADA and Drives), if that better serves your needs. We can also simply provide additional hours in your design and manufacture process.`,
  },
  machinery: {
    title: `Bespoke 
    Machinery`,
    text: `Cornfield Engineering Solutions are a Lenze Performance Partner, this gives us access to some of the world's best drive solutions.

    If your machine requires indexing, positioning, following or in fact any movement contact us to generate a solution.
    
    We can provide a full solution, integrating the Lenze control and mechanical elements with a wider solution. Alternatively, we can provide the control elements programmed to your specification.`,
  },
  titleSubtitleSection: {
    title: "Cornfield Engineering Solutions",
    sub_title: "All Things Industrial Machine",
  },
  caseStudies: {
    title: "Customer Case Studies",
    eaton: {
      tags: [],
      path: "/case-studies",
      title: "Eaton Ltd",
      sub_title: "High Precison Indexing",
      text: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente sunt iste voluptatem unde quisquam error iure debitis delectus. 
      
      Commodi molestiae praesentium repellendus rem ipsam ipsum vero nihil natus obcaecati similique?`,
    },
    mbs: {
      tags: [],
      path: "/case-studies",
      title: "MBS Ltd",
      sub_title: "Precision Positioning",
      text: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente sunt iste voluptatem unde quisquam error iure debitis delectus. 
      
      Commodi molestiae praesentium repellendus rem ipsam ipsum vero nihil natus obcaecati similique?`,
    },
    portland: {
      tags: [],
      path: "/case-studies",
      title: "Portland Dyeing Co",
      sub_title: "Rapid Replacement",
      text: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente sunt iste voluptatem unde quisquam error iure debitis delectus. 
      
      Commodi molestiae praesentium repellendus rem ipsam ipsum vero nihil natus obcaecati similique?`,
    },
  },
};
