import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: Home,
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/case-studies",
    name: "CaseStudies",
    component: () => import(/* webpackChunkName: "case-studies" */ "../views/CaseStudies.vue"),
  },
  {
    path: "/mbs-case-study",
    name: "mbs-case-study",
    component: () =>
      import(/* webpackChunkName: "mbs-case-study" */ "../views/caseStudies/mbs-case-study.vue"),
  },
  {
    path: "/eaton-case-study",
    name: "eaton-case-study",
    component: () =>
      import(
        /* webpackChunkName: "eaton-case-study" */ "../views/caseStudies/eaton-case-study.vue"
      ),
  },
  {
    path: "/portland-case-study",
    name: "portland-case-study",
    component: () =>
      import(
        /* webpackChunkName: "portland-case-study" */ "../views/caseStudies/portland-case-study.vue"
      ),
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "case-studies" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
