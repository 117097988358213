import "./main.css";

import App from "./App.vue";
import { OvermindPlugin } from "./overmind";
import Vue from "vue";
import router from "./router";
import { VLazyImagePlugin } from "v-lazy-image";

Vue.use(VLazyImagePlugin);
Vue.use(OvermindPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount("#app");
