<template>
  <div class="flex w-full bg-gray-500 py-8">
    <div class="container mx-auto">
      <div class="flex flex-col mb-8">
        <div class="flex justify-around">
          <FooterLink path="/home" text="Home" />
          <FooterLink path="/about" text="About" />
          <FooterLink path="/case-studies" text="Case Studies" />
          <FooterLink path="/contact" text="Contact" />
        </div>
      </div>
      <div class="flex justify-center">
        <div class="text-lg text-gray-900">© Cornfield Engineering Solutions 2020</div>
      </div>
      <div class="flex flex-col items-center">
        <p class="text-gray-900 text-md">Cornfield Engineering Solutions Ltd</p>
        <p class="text-gray-900 text-md">Unit 5, Brindley Court</p>
        <p class="text-gray-900 text-md">Victoria Business Park</p>
        <p class="text-gray-900 text-md">Brown Lees</p>
        <p class="text-gray-900 text-md">Stoke-on-Trent</p>
        <p class="text-gray-900 text-md">ST8 7PP</p>
        <p class="text-gray-900 text-md">Tel: 01782 511161</p>
        <p class="text-gray-900 text-md">Email: sales@cornfieldeng.co.uk</p>
      </div>
    </div>
  </div>
</template>

<script>
import FooterLink from "@/components/FooterLink";
export default {
  name: "FooterNav",
  components: { FooterLink },
};
</script>

<style></style>
