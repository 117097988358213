export const addFilter = ({ state }, value) => {
  state.tags[value].selected = true;
  if (value != "all") {
    state.tags.all.selected = false;
  }
  if (value === "all") {
    state.tags.map((_, key) => {
      if (key != "all") {
        state.tags[key].selected = false;
      }
    });
  }
};

export const removeFilter = ({ state }, value) => {
  state.tags[value].selected = false;
};

export const updateContactEmail = ({ state }, value) => {
  state.contact.email = value;
};
export const updateContactFirstName = ({ state }, value) => {
  state.contact.firstName = value;
};
export const updateContactLastName = ({ state }, value) => {
  state.contact.lastName = value;
};
export const updateContactMessage = ({ state }, value) => {
  state.contact.message = value;
};
