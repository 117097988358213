<template>
  <div class="w-full lg:w-1/3 p-4">
    <div class="h-full flex flex-col bg-gray-100 rounded shadow-md p-4">
      <div class="text-4xl font-bold mb-6 whitespace-pre-line">
        {{ title }}
      </div>
      <div class="text-lg whitespace-pre-line	">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleSubtitleTextCard",
  props: ["title", "text"],
};
</script>

<style></style>
