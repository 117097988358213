export default {
  title: "Customer Case Studies",
  sub_title: "The stories behind Cornfield Engineering's Success",
  studies: {
    mbs: {
      tags: ["positioning", "precision"],
      img: "/images/about_header.jpg",
      path: "/mbs-case-study",
      title: "Precision Positioning",
      sub_title: "Precision Positioning",
      summary: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente sunt iste voluptatem unde quisquam error iure debitis delectus.

      Commodi molestiae praesentium repellendus rem ipsam ipsum vero nihil natus obcaecati similique?`,
    },
    eaton: {
      tags: ["indexing", "precision"],
      img: "/images/caseStudies_header.jpg",
      path: "/eaton-case-study",
      title: "Precison Indexing",
      sub_title: "High Precison Indexing",
      summary: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente sunt iste voluptatem unde quisquam error iure debitis delectus.

      Commodi molestiae praesentium repellendus rem ipsam ipsum vero nihil natus obcaecati similique?`,
    },
    portland: {
      tags: ["upgrades"],
      img: "/images/home_header.jpg",
      path: "/portland-case-study",
      title: "Rapid Replacement",
      sub_title: "Rapid Replacement",
      summary: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente sunt iste voluptatem unde quisquam error iure debitis delectus.

      Commodi molestiae praesentium repellendus rem ipsam ipsum vero nihil natus obcaecati similique?`,
    },
  },
};
