import * as text from "./texts";
import { derived } from "overmind";
export default {
  app_title: "Cornfield Engineering Solutions",
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  },
  text,
  tags: {
    all: { selected: true },
    positioning: { selected: false },
    precision: { selected: false },
    upgrades: { selected: false },
  },
  filteredStudies: derived(state => {
    const filtered = new Set();
    if (state.tags.all.selected) {
      return state.text.caseStudies.studies;
    } else {
      Object.entries(state.tags).map(([key, value]) => {
        return Object.values(state.text.caseStudies.studies).map(study => {
          if (value.selected && study.tags.includes(key)) {
            filtered.add(JSON.stringify(study));
          }
        });
      });
      return [...filtered].map(item => {
        if (typeof item === "string") return JSON.parse(item);
        else if (typeof item === "object") return item;
      });
    }
  }),
};
