<template>
  <div id="home-page" class="flex flex-col bg-white">
    <PageHeader
      :title="state.text.home.header_title"
      :sub_title="state.text.home.header_subtitle"
      img="/images/home_header.webp"
      imgSet=" /images/mobile/home_header.png 400w ,/images/home_header.webp 960w"
    />
    <CompanyLogos />
    <TitleTextButton
      :title="state.text.home.intro_section.title"
      :text="state.text.home.intro_section.text"
      :btn_text="state.text.home.intro_section.btn_text"
      path="/contact"
    />
    <div class="container mx-auto flex flex-wrap py-8 lg:py-16">
      <TitleSubtitleTextCard
        :title="state.text.home.control.title"
        :text="state.text.home.control.text"
      />
      <TitleSubtitleTextCard
        :title="state.text.home.upgrades.title"
        :text="state.text.home.upgrades.text"
      />
      <TitleSubtitleTextCard
        :title="state.text.home.machinery.title"
        :text="state.text.home.machinery.text"
      />
    </div>
    <TitleSubtitleSection
      :title="state.text.home.titleSubtitleSection.title"
      :sub_title="state.text.home.titleSubtitleSection.sub_title"
    />

    <div class="container mx-auto flex flex-wrap py-8 lg:py-16">
      <div
        class="w-full py-4 text-4xl lg:text-5xl font-bold text-gray-900 text-center text-gray-900"
      >
        Customer Case Studies
      </div>
      <CaseStudyCard
        :title="state.text.caseStudies.studies.eaton.title"
        :tags="state.text.caseStudies.studies.eaton.tags"
        :img="state.text.caseStudies.studies.eaton.img"
        :sub_title="state.text.caseStudies.studies.eaton.sub_title"
        :summary="state.text.caseStudies.studies.eaton.summary"
        :path="state.text.caseStudies.studies.eaton.path"
      />
      <CaseStudyCard
        :title="state.text.caseStudies.studies.mbs.title"
        :tags="state.text.caseStudies.studies.mbs.tags"
        :img="state.text.caseStudies.studies.mbs.img"
        :sub_title="state.text.caseStudies.studies.mbs.sub_title"
        :summary="state.text.caseStudies.studies.mbs.summary"
        :path="state.text.caseStudies.studies.mbs.path"
      />
      <CaseStudyCard
        :title="state.text.caseStudies.studies.portland.title"
        :tags="state.text.caseStudies.studies.portland.tags"
        :img="state.text.caseStudies.studies.portland.img"
        :sub_title="state.text.caseStudies.studies.portland.sub_title"
        :summary="state.text.caseStudies.studies.portland.summary"
        :path="state.text.caseStudies.studies.portland.path"
      />

      <LinkButton path="/case-studies" text="All Case Studies" />
    </div>
    <PartnerSupport />
  </div>
</template>

<script>
// @ is an alias to /src
import TitleSubtitleTextCard from "@/components/TitleSubtitleTextCard";
import TitleSubtitleSection from "@/components/TitleSubtitleSection";
import CaseStudyCard from "@/components/CaseStudyCard";
import LinkButton from "@/components/LinkButton";
import PageHeader from "@/components/PageHeader";
import TitleTextButton from "@/components/TitleTextButton";
import CompanyLogos from "@/components/CompanyLogos";
import PartnerSupport from "@/components/PartnerSupport";

export default {
  name: "Home",
  components: {
    PageHeader,
    TitleTextButton,
    TitleSubtitleTextCard,
    TitleSubtitleSection,
    CaseStudyCard,
    LinkButton,
    CompanyLogos,
    PartnerSupport,
  },
};
</script>
