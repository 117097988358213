<template>
  <div class="flex w-full py-2 px-4 items-center justify-between bg-gray-300">
    <NavLogo />
    <div class="flex px-4">
      <NavLink path="/home" :text="state.text.nav.home" />
      <NavLink path="/about" :text="state.text.nav.about" />
      <NavLink path="/case-studies" :text="state.text.nav.case_studies" />
      <NavLink path="/contact" :text="state.text.nav.contact" />
    </div>
  </div>
</template>

<script>
import NavLink from "@/components/NavLink";
import NavLogo from "@/components/NavLogo";
export default {
  name: "TopNav",
  components: { NavLink, NavLogo },
};
</script>

<style></style>
