<template>
  <div class="w-full flex bg-white py-8">
    <div class="container mx-auto">
      <div class="flex flex-wrap  justify-around p-4">
        <img
          height="48"
          width="250"
          class="my-4 w-1/2 lg:w-2/12"
          src="/images/wago_logo_h48.png"
          alt="Wago Logo"
        />
        <img
          height="48"
          width="250"
          class="my-4 w-1/2 lg:w-2/12"
          src="/images/lenze_logo_h48.png"
          alt="Lenze Logo"
        />
        <img
          height="48"
          width="250"
          class="my-4 w-1/2 lg:w-2/12"
          src="/images/mitsubishi_logo_h48.png"
          alt="Mitsubishi Logo"
        />
        <img
          height="48"
          width="250"
          class="my-4 w-1/2 lg:w-2/12"
          src="/images/siemens_logo_h48.png"
          alt="Siemens Logo"
        />
        <img
          height="48"
          width="250"
          class="my-4 w-1/2 lg:w-2/12"
          src="/images/pilz_logo_h48.png"
          alt="Pilz Logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyLogos",
};
</script>

<style></style>
