<template>
  <a href="/">
    <img
      height="48"
      width="177"
      class="h-12"
      src="/images/ces_logo.svg"
      alt="Cornfield Engineering Logo"
    />
  </a>
</template>

<script>
export default {
  name: "NavLogo",
};
</script>
