<template>
  <div class="page-header h-full relative w-full flex flex-col items-center justify-center">
    <v-lazy-image
      :src="img"
      :srcset="imgSet"
      sizes="(max-width: 400px) 960px"
      src-placeholder="/images/about_header_copy.jpg"
      alt="PLC Layout"
    />
    <div class="bg-white-05 w-full flex flex-col items-center justify-center absolute z-10 p-2">
      <div class="flex text-4xl text-center lg:text-5xl font-bold text-gray-900">{{ title }}</div>
      <div class="flex text-xl text-center lg:text-3xl text-gray-900 text-gray-900">
        {{ sub_title }}
      </div>
      <LinkButton v-if="!hideButton" path="/contact" text="Contact Us" />
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton";
export default {
  name: "PageHeader",
  props: ["title", "sub_title", "img", "imgSet", "hideButton"],
  components: { LinkButton },
};
</script>

<style scoped>
.page-header {
  height: 400px;
}
.v-lazy-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
