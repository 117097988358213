<template>
  <div class="w-full bg-gray-100 flex ">
    <div
      class="container mx-auto bg-gray-100 flex flex-col items-center justify-center py-8 lg:py-16"
    >
      <div class="w-full text-center py-4 text-3xl lg:text-5xl font-bold text-gray-900">
        Class Leading Support
      </div>
      <div class="flex flex-wrap w-full lg:w-3/4 justify-around py-2 lg:py-4">
      <img
          height="200"
          width="300"
          class="my-2 w-11/12 lg:mx-4 bg-white"
          src="/images/ces_logo.svg"
          alt="Mitsubishi Partner Programme"
        />
        <img
          height="200"
          width="300"
          class="my-2 w-5/12 lg:mx-4"
          src="/images/lenze_performance_partner.png"
          alt="Lenze Performance Partner"
        />
        <img
          height="200"
          width="300"
          class="my-2 w-5/12 lg:mx-4"
          src="/images/mitsubishi_partner_programme.png"
          alt="Mitsubishi Partner Programme"
        />
         
      </div>
      <div class="flex w-full lg:w-3/4 text-lg  px-4 py-4">
        Lipsum dolor sit amet consectetur adipisicing elit. Inventore eligendi nihil, dicta rem
        veniam numquam amet exercitationem eos, nulla voluptatibus ad facere. Labore vitae velit
        fugiat voluptatibus doloremque aliquid culpa.
      </div>
      <LinkButton path="/contact" text="Contact Us" />
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton";

export default {
  name: "PartnerSupport",
  components: { LinkButton },
};
</script>

<style></style>
