<template>
  <div class="w-full bg-gray-100">
    <div
      class="container mx-auto bg-gray-100 flex flex-col items-center justify-center py-8 lg:py-16 px-4"
    >
      <div class="py-4 text-3xl lg:text-5xl font-bold text-gray-900">{{ title }}</div>
      <p class="text-lg whitespace-pre-line">{{ text }}</p>
      <div class="my-4"></div>
      <LinkButton :path="path" :text="btn_text" />
    </div>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton";
export default {
  name: "TitleTextButton",
  props: ["title", "text", "path", "btn_text"],
  components: { LinkButton },
};
</script>
