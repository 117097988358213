<template>
  <div class="w-full lg:w-1/3 p-4">
    <div
      class="transform duration-150 hover:scale-105 hover:shadow-xl h-full flex flex-col bg-gray-100 rounded shadow-lg p-4"
    >
      <div class="w-full flex">
        <v-lazy-image
        :src="img"
        sizes="(max-width: 400px) 960px"
        src-placeholder="/images/about_header_copy.jpg"
        alt="PLC Layout"
      />
      </div>
      <div class="w-full flex">
        <div class="font-bold mr-4">Tags</div>
        <div class="capitalize mr-2" :key="index" v-for="(tag, index) in tags">
          {{ tag }}
          <span v-if="index != Object.keys(tags).length - 1">,</span>
        </div>
      </div>
      <div class="text-gray-900 text-4xl font-bold my-4">
        {{ title }}
      </div>
      <div class="text-gray-900 flex-1 text-lg whitespace-pre-line">
        {{ summary }}
      </div>
      <a :href="path" class="text-purple-900 hover:text-purple-500 text-md font-bold mt-4">
        View Study
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStudyCard",
  props: ["title", "sub_title", "summary", "path", "scale", "img", "tags"],
};
</script>

<style>
.v-lazy-image {
  width: 100%;
  object-fit: cover;
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
